import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    blue?: Palette['primary']
    green?: Palette['primary']
    orange?: Palette['primary']
    gray?: Palette['primary']
    lightGray?: Palette['primary']
    inputOutline?: Palette['primary']
    icons?: Palette['primary']
    black?: Palette['primary']
    purple?: Palette['primary']
    lightBlue?: Palette['primary']
    softBlue?: Palette['primary']
    darkRed?: Palette['primary']
    softRed?: Palette['primary']
    skyBlue?: Palette['primary']
    aliceBlue?: Palette['primary']
    darkCharcoal?: Palette['primary']
    lightBeige?: Palette['primary']
    errorRed?: Palette['primary']
    sapphire?: Palette['primary']
    ironGrey: Palette['primary']
    chipBlue?: Palette['primary']
    chipIndigo?: Palette['primary']
    chipOrange?: Palette['primary']
    chipGrey?: Palette['primary']
    chipViolet?: Palette['primary']
    chipWhite?: Palette['primary']
    chipGreen?: Palette['primary']
    chipRed?: Palette['primary']
    chipYellow?: Palette['primary']
    darkBlue?: Palette['primary']
    chartBlue?: Palette['primary']
    chartGreen?: Palette['primary']
    topbar?: Palette['primary']
  }

  interface PaletteOptions {
    blue?: PaletteOptions['primary']
    green?: PaletteOptions['primary']
    orange?: PaletteOptions['primary']
    gray?: PaletteOptions['primary']
    lightGray?: PaletteOptions['primary']
    inputOutline?: PaletteOptions['primary']
    icons?: PaletteOptions['primary']
    black?: PaletteOptions['primary']
    purple?: PaletteOptions['primary']
    lightBlue?: PaletteOptions['primary']
    softBlue?: PaletteOptions['primary']
    darkRed?: PaletteOptions['primary']
    softRed?: PaletteOptions['primary']
    skyBlue?: PaletteOptions['primary']
    aliceBlue?: PaletteOptions['primary']
    darkCharcoal?: PaletteOptions['primary']
    lightBeige?: PaletteOptions['primary']
    errorRed?: PaletteOptions['primary']
    sapphire?: PaletteOptions['primary']
    ironGrey: PaletteOptions['primary']
    chipBlue?: PaletteOptions['primary']
    chipIndigo?: PaletteOptions['primary']
    chipOrange?: PaletteOptions['primary']
    chipGrey?: PaletteOptions['primary']
    chipViolet?: PaletteOptions['primary']
    chipWhite?: PaletteOptions['primary']
    chipGreen?: PaletteOptions['primary']
    chipRed?: PaletteOptions['primary']
    chipYellow?: PaletteOptions['primary']
    darkBlue?: PaletteOptions['primary']
    chartBlue?: PaletteOptions['primary']
    chartGreen?: PaletteOptions['primary']
    topbar?: PaletteOptions['primary']
  }
}

let theme = createTheme({
  palette: {
    text: {
      primary: '#212121',
      secondary: '#666666',
      disabled: '#AFAFAF',
    },
    topbar: {
      main: '#FDFCFC',
    },
    background: {
      default: '#F2F4F6',
      paper: '#FBFDFF',
    },
    primary: {
      main: '#003776',
    },
    secondary: {
      main: '#1976D2',
    },
    inputOutline: {
      main: '#E0E0E0',
    },
    icons: {
      main: '#1A1A1A',
    },
    blue: {
      main: '#0087FA',
    },
    green: {
      main: '#7EBB41',
      dark: '#009A72',
    },
    orange: {
      main: '#F37302',
      light: '#F7B679',
    },
    gray: {
      main: '#EBEBEB',
      dark: '#8B8589',
    },
    lightGray: {
      main: '#F5F5F5',
      dark: '#B5B8C0',
    },
    black: {
      main: '#262626',
    },
    purple: {
      main: '#D196FF',
      light: '#91A1F9',
    },
    lightBlue: {
      main: '#82C1EB',
      light: '#E6F2FB',
    },
    softBlue: {
      main: '#E3EEF9',
    },
    darkRed: {
      main: '#D32F2F',
    },
    softRed: {
      main: '#FDF7F7',
    },
    skyBlue: {
      main: '#80C3FD',
    },
    aliceBlue: {
      main: '#E5F6FD',
    },
    darkBlue: {
      main: '#003776',
      light: '#80ACE8',
      dark: '#003776',
      contrastText: '#E4F1FC',
    },
    darkCharcoal: {
      main: '#262B30',
    },
    lightBeige: {
      main: '#BDA873',
    },
    errorRed: {
      main: '#CA2320',
      light: '#CA2320CC',
    },
    sapphire: {
      main: '#C8E0FD',
      light: '#C8E0FDCC',
      dark: '#0059D1',
      contrastText: '#C8E0FD',
    },
    ironGrey: {
      main: '#F8FAFC',
    },
    chipBlue: {
      main: '#D3D5F8',
      contrastText: '#454BD7',
    },
    chipIndigo: {
      main: '#E1CEF9',
      contrastText: '#782CD6',
    },
    chipOrange: {
      main: '#FFF3E5',
      contrastText: '#BF360C',
    },
    chipGrey: {
      main: '#DADADA',
      dark: '#6D6D6D',
      contrastText: '#424242',
    },
    chipViolet: {
      main: '#ECC7ED',
      contrastText: '#980499',
    },
    chipWhite: {
      main: '#FFFFFF',
    },
    chipGreen: {
      main: '#E8F5E9',
      dark: '#2E7D32',
    },
    chipRed: {
      main: '#FFF6F9',
      dark: '#D32F2F',
    },
    chipYellow: {
      main: '#FFF8E1',
    },
    chartBlue: {
      main: '#A7D3FE',
    },
    chartGreen: {
      main: '#9DCD98',
      light: '#9DCD98',
      dark: '#015A3C',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1280,
      lg: 1920,
      xl: 2560,
    },
  },
})

theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.up('xl')]: {
    fontSize: '7.8rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '4.8rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.up('xl')]: {
    fontSize: '4.875rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '3rem',
  },
}

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.up('xl')]: {
    fontSize: '3.9rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.4rem',
  },
}

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.7625rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.7rem',
  },
}

theme.typography.h5 = {
  ...theme.typography.h5,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.95rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1.2rem',
  },
}

theme.typography.h6 = {
  ...theme.typography.h6,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.625rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '1rem',
  },
}

theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.8rem',
  },
}

theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1375rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.7rem',
  },
}

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.8rem',
  },
}

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1375rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.7rem',
  },
}

theme.typography.button = {
  ...theme.typography.button,
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1375rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.7rem',
  },
}

theme.typography.caption = {
  ...theme.typography.caption,
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.975rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.6rem',
  },
}

theme.typography.overline = {
  ...theme.typography.overline,
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.813rem',
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.5rem',
  },
}

export default theme
